import { Logo } from '@moller/design-system';
import NextImage from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { BsCreditCard } from 'react-icons/bs';
import { FaCheck, FaRegCircleCheck } from 'react-icons/fa6';
import { FiArrowRight } from 'react-icons/fi';
import { IoIosTimer } from 'react-icons/io';
import { CampaignSingularType, ServicePageType } from '../../types';
import cn from '../../utils/cn';
import fetchSanityData from '../../utils/fetchSanityData';
import Disclaimer from '../Disclaimer';
import HeroCTA from '../HeroCTA';
import PortableText from '../PortableText';
import StepsCar from '../StepsCar';
import AlertCard from '../cards/AlertCard';
import ArticleCard, { ArticleCardHorizontal } from '../cards/ArticleCard';
import CampaignBox from '../CampaignBox';

interface ServicePageProps {
  service: ServicePageType;
}

function ServicePage({ service }: ServicePageProps) {
  const {
    _id,
    overline,
    title,
    formOverride,
    price,
    duration,
    priceDisclaimer,
    includedTitle,
    includedText,
    alertTitle,
    relatedArticlesFeature,
    relatedArticlesStandard,
    bodyText,
    description,
    featuredArticle,
    customerBenefits,
    steps,
    arguments: serviceArguments,
    disclaimer,
    loginOverride,
    serviceId,
    articles,
    relatedDealers,
    argumentsTitle,
  } = service;
  const [campaigns, setCampaigns] = useState<CampaignSingularType[]>([]);

  useEffect(() => {
    const fetchCampaigns: () => void = async () => {
      const response = await fetchSanityData('serviceCampaigns', { id: _id });
      setCampaigns(response.campaigns);
    };

    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let disclaimerNo = 1;

  const campaignDisclaimer =
    Array.isArray(campaigns) && !!campaigns.length
      ? `${campaigns
          .filter((item) => item.disclaimer)
          .map(
            (campaign, index) => `${new Array(index + 1).fill('*').join('')}${campaign.disclaimer}`,
          )
          .join('\n')}\n\n`
      : undefined;

  const allRelatedArticles: { title: string }[] = [];
  if (Array.isArray(relatedArticlesStandard)) {
    allRelatedArticles.push(...relatedArticlesStandard);
  }
  if (Array.isArray(relatedArticlesFeature)) {
    allRelatedArticles.push(...relatedArticlesFeature);
  }

  return (
    <>
      <HeroCTA
        overline={overline}
        title={title}
        primaryButton={
          loginOverride?.login
            ? {
                href: `${loginOverride?.login?.cta.url}?serviceIds=${serviceId}`,
                linkText: loginOverride.linkText || loginOverride.login.cta.linkText,
              }
            : undefined
        }
        formOverride={formOverride}
      />

      {Array.isArray(campaigns) && !!campaigns.length && (
        <div className="max-w-8xl mx-auto my-16 flex flex-col px-6 sm:my-20 sm:px-10 lg:px-20">
          <div className="flex-col gap-3">
            {campaigns.map((campaign) => {
              return (
                <CampaignBox
                  key={campaign._id}
                  campaign={campaign}
                  itemNo={campaign.disclaimer ? disclaimerNo++ : 0}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* TITLE, PRICE, DURATION */}
      {(price || duration) && (
        <div className="max-w-8xl mx-auto my-16 flex flex-col px-6 sm:my-20 sm:px-10 lg:px-20">
          <div className="mx-auto w-full max-w-4xl">
            <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{title}</h2>
            <div className="divide-primary/20 border-primary/20 flex flex-col gap-2 border-y-2 p-2 pl-0 md:flex-row md:gap-12 md:divide-x-2 md:p-6 ">
              {price && (
                <div className="flex items-center gap-5 py-2">
                  <span className="bg-primary text-background rounded-full p-2">
                    <BsCreditCard className="h-6 w-6 md:h-8 md:w-8" />
                  </span>

                  <span className="text-xl font-semibold md:text-2xl">Pris: {price}</span>
                </div>
              )}
              {duration && (
                <div className="flex items-center gap-5 py-2 md:[&:not(:only-child)]:pl-12">
                  <span className="bg-primary text-background rounded-full p-1">
                    <IoIosTimer className="h-8 w-8 md:h-10 md:w-10" />
                  </span>
                  <span className="text-xl font-semibold md:text-2xl">Varighet: {duration}</span>
                </div>
              )}
            </div>
            {priceDisclaimer && priceDisclaimer.length < 250 && (
              <p className="mt-2 text-sm">{priceDisclaimer}</p>
            )}
          </div>
        </div>
      )}

      {alertTitle && description && (
        <div className="max-w-8xl mx-auto my-16 flex flex-col px-6 sm:my-20 sm:px-10 lg:px-20">
          <div className="mx-auto w-full max-w-4xl">
            <AlertCard title={alertTitle} description={description} />
          </div>
        </div>
      )}

      {Array.isArray(serviceArguments) && !!arguments.length && (
        <section className="bg-[#C6DAFF]">
          <div className="max-w-8xl mx-auto px-6 py-16 sm:px-10 sm:py-20 lg:px-20 lg:py-28">
            <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">
              {argumentsTitle || 'Velg Møller Bil'}
            </h2>

            <div
              className={cn(
                'grid gap-5',
                serviceArguments.length === 2 && 'grid-cols-1 sm:grid-cols-2',
                serviceArguments.length === 3 && 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3',
                serviceArguments.length === 4 && 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4',
              )}
            >
              {serviceArguments.map((item) => {
                return (
                  <div
                    key={item.title}
                    className={cn(
                      'bg-info-container border-on-info-container/10 relative flex flex-col gap-4 rounded-sm border p-6 lg:p-8',
                    )}
                  >
                    <FaCheck className=" bg-on-info-container text-info-container h-8 w-8 rounded-full p-2" />
                    <div>
                      <h3 className="mb-2 break-words text-2xl font-semibold">{item.title}</h3>
                      <p className="text-on-info-container lg:text-lg">{item.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}

      {includedText && (
        <section className="bg-transparent">
          <div className="max-w-8xl mx-auto px-6 py-16 sm:px-10 sm:py-20 lg:px-20 lg:py-28">
            <div className="bg-primary text-on-primary mx-auto flex max-w-4xl items-center justify-center rounded-md px-8 py-10 md:py-14">
              <div className="max-w-[75ch]">
                <h2 className="mb-3 text-3xl font-semibold sm:mb-5">
                  {includedTitle || 'Dette er inkludert'}
                </h2>
                <PortableText value={includedText} />
              </div>
            </div>
          </div>
        </section>
      )}

      {Array.isArray(steps) && !!steps.length && (
        <section className="bg-background">
          <div className="max-w-8xl mx-auto px-6 py-16 sm:px-10 sm:py-20 lg:px-20 lg:py-28">
            <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Slik foregår det</h2>

            {/* DESKTOP */}
            <div className="max-md:hidden">
              <div className="grid gap-5 md:grid-cols-3">
                {steps.map((step) => {
                  return (
                    <div key={step.title} className="relative flex flex-col gap-2">
                      <h3 className="mb-2 text-2xl font-semibold">{step.title}</h3>
                      <PortableText value={step.description} />
                    </div>
                  );
                })}
              </div>
              <StepsCar />
            </div>

            {/* MOBILE */}
            <div className="md:hidden">
              {steps?.map((step, i) => {
                const { title: stepTitle, description: stepDescription } = step;
                return (
                  <div
                    key={`mobile-step-${stepTitle}`}
                    className={cn(
                      'mb-10 flex gap-5 border-b-2 border-black/40',
                      i % 2 !== 0 && 'flex-row-reverse justify-between',
                    )}
                  >
                    <div className="flex flex-col items-center">
                      <div className="relative -mb-1 aspect-square w-10 shrink-0">
                        <NextImage src={`/assets/images/step${i + 1}.svg`} alt="" fill />
                      </div>
                      <div className="h-full min-h-[10px] w-[2px] bg-black" />
                    </div>
                    <div className="my-5">
                      <h3 className="mb-2 text-2xl font-semibold">{stepTitle}</h3>
                      <PortableText value={stepDescription} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}

      {Array.isArray(customerBenefits) && !!customerBenefits.length && (
        <section className="bg-[#C6DAFF]">
          <div className="max-w-8xl mx-auto px-6 py-16 sm:px-10 sm:py-20 lg:px-20 lg:py-28">
            <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Hos oss</h2>

            <div className="grid gap-5 lg:grid-cols-3">
              {customerBenefits.map((item) => {
                return (
                  <div
                    key={item.title}
                    className={cn(
                      'bg-info-container border-on-info-container/10 relative flex flex-col gap-4 rounded-sm border p-6 lg:p-8',
                    )}
                  >
                    <FaCheck className="text-primary h-8 w-8 rounded-full border-2 border-[#001946] p-1" />
                    <div>
                      <h3 className="mb-2 break-words text-2xl font-semibold">{item.title}</h3>
                      <PortableText value={item.bodyText} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}

      {featuredArticle && (
        <section className="bg-transparent">
          <div className="max-w-8xl mx-auto px-6 py-16 sm:px-10 sm:py-20 lg:px-20 lg:py-28">
            <div className="mx-auto max-w-4xl border border-black/5">
              <div className="max-sm:hidden">
                <ArticleCardHorizontal article={featuredArticle} />
              </div>
              <div className="sm:hidden">
                <ArticleCard article={featuredArticle} />
              </div>
            </div>
          </div>
        </section>
      )}

      {bodyText && (
        <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
          <PortableText value={bodyText} />
        </div>
      )}

      {allRelatedArticles.length > 0 && (
        <section className="max-w-8xl mx-auto mb-16 px-6 sm:mb-20 sm:px-10 lg:px-20">
          <div className="mx-auto max-w-4xl">
            <h2 className="mb-5 text-2xl font-semibold lg:mb-10 lg:text-3xl">Nyttig å vite</h2>
            <div className="grid w-full gap-5">
              {allRelatedArticles.map((article: any) => {
                return (
                  <>
                    <div className="max-sm:hidden">
                      <ArticleCardHorizontal article={article} />
                    </div>
                    <div className="sm:hidden">
                      <ArticleCard article={article} />
                    </div>
                  </>
                  /*    <Link
                    key={article.title}
                    href={article.href || '/'}
                    className="bg-background flex items-center justify-between gap-8 rounded-md p-6 duration-300 hover:bg-[#ddd9d3] active:bg-[#ada9a5] md:px-10"
                  >
                    <div className="flex flex-col">
                      {article.overline && (
                        <span
                          className="text-tertiary-variant mb-2 inline-block text-lg font-semibold md:text-xl"
                          aria-hidden
                        >
                          {article.overline}
                        </span>
                      )}
                      <span className="md:text-xl">{article.title}</span>
                    </div>
                    <FiArrowRight className="h-5 min-h-[20px] w-5 min-w-[20px]" />
                  </Link> */
                );
              })}
            </div>
          </div>
        </section>
      )}

      {(campaignDisclaimer || disclaimer) && (
        <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
          <Disclaimer text={`${campaignDisclaimer || ''}${disclaimer || ''}`} />
          {priceDisclaimer && priceDisclaimer.length > 250 && <Disclaimer text={priceDisclaimer} />}
        </div>
      )}
    </>
  );
}

export default ServicePage;
