import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CampaignSingularType, RelatedDealerType, TopicPageType } from '../../types';
import fetchSanityData from '../../utils/fetchSanityData';
import Container from '../Container';
import Disclaimer from '../Disclaimer';
import HeroCTA from '../HeroCTA';
import PortableText from '../PortableText';
import AllArticlesButton from '../cards/AllArticlesButton';
import ArticleCard from '../cards/ArticleCard';
import LinkCard from '../cards/LinkCard';
import CampaignBox from '../CampaignBox';
import Section from '../Section';

interface TopicPageProps {
  topic: TopicPageType;
}
function TopicPage({ topic }: TopicPageProps) {
  const {
    _id,
    overline,
    title,
    description,
    bodyText,
    image,
    formOverride,
    loginOverride,
    relatedDealers,
    featureArticles,
    disclaimer,
  } = topic;

  const router = useRouter();
  const [dealers, setDealers] = useState<RelatedDealerType[] | undefined>(relatedDealers);
  const [campaigns, setCampaigns] = useState<CampaignSingularType[]>([]);

  useEffect(() => {
    const fetchDealers: (groq: string, params: { [key: string]: string }) => void = async (
      groq,
      params,
    ) => {
      const data = await fetchSanityData(groq, params);

      setDealers(data);
    };

    if (router.query.dealerId) {
      fetchDealers('dealerById', { dealerId: router.query.dealerId as string });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  useEffect(() => {
    const fetchCampaigns: () => void = async () => {
      const response = await fetchSanityData('topicCampaigns', { id: _id });
      setCampaigns(response.campaigns);
    };
    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let disclaimerNo = 1;

  const disclaimerText = `${
    Array.isArray(campaigns)
      ? campaigns
          .filter((item) => item.disclaimer)
          .map(
            (campaign, index) => `${new Array(index + 1).fill('*').join('')}${campaign.disclaimer}`,
          )
          .join('\n')
      : undefined
  }\n${disclaimer || ''}`;

  return (
    <>
      <HeroCTA
        title={title}
        description={description}
        overline={overline}
        image={image}
        primaryButton={
          loginOverride?.login
            ? {
                href: loginOverride.login.cta.url,
                linkText: loginOverride.linkText || loginOverride.login.cta.linkText,
              }
            : undefined
        }
        formOverride={formOverride}
      />
      {Array.isArray(campaigns) && !!campaigns.length && (
        <>
          {campaigns.map((campaign) => {
            return (
              <CampaignBox
                key={campaign._id}
                campaign={campaign}
                itemNo={campaign.disclaimer ? disclaimerNo++ : 0}
              />
            );
          })}
        </>
      )}

      {bodyText && (
        <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
          <PortableText value={bodyText} />
        </div>
      )}

      {!!featureArticles.length && (
        <Section>
          <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">Relaterte artikler</h2>

          <div className="mb-10 grid gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {featureArticles.map((article) => {
              return <ArticleCard key={article.title} article={article} />;
            })}
          </div>
          <AllArticlesButton />
        </Section>
      )}
      <Container>
        {Array.isArray(dealers) &&
          !!dealers.length &&
          (dealers.length === 1 ? (
            <div className="border-b">
              <h2 className="text-display-3 border-b pb-4">Kontakt forhandler</h2>
              <LinkCard
                key={dealers[0]._id}
                title={dealers[0].title}
                href={`/forhandler/${dealers[0].slug}`}
                color="beige-dark"
                rounded
              />
            </div>
          ) : (
            <>
              <h2 className="mb-5 text-2xl font-semibold lg:mb-10 lg:text-3xl">
                Relaterte forhandlere
              </h2>
              <div className="grid gap-5">
                {dealers.map((dealer) => {
                  return (
                    <LinkCard
                      key={dealer._id}
                      title={dealer.title}
                      href={dealer.href}
                      color="beige-dark"
                      rounded
                    />
                  );
                })}
              </div>
            </>
          ))}

        <Disclaimer text={disclaimerText} />
      </Container>
    </>
  );
}

export default TopicPage;
