import { useEffect, useState } from 'react';
import { CampaignSingularType, EditorialPageType } from '../../types';
import fetchSanityData from '../../utils/fetchSanityData';
import Disclaimer from '../Disclaimer';
import HeroCTA from '../HeroCTA';
import CampaignCard from '../cards/CampaignCard';
import Section from '../Section';
import CampaignBox from '../CampaignBox';

interface PageProps {
  content: EditorialPageType;
}

function EditorialPage({ content }: PageProps) {
  const {
    _id,
    description,
    overline,
    title,
    image,
    formOverride,
    loginOverride,
    entryLists,
    relatedEmployees,
  } = content;

  const [campaigns, setCampaigns] = useState<CampaignSingularType[]>([]);

  const campaignSort = (a: CampaignSingularType) => {
    if (a.campaignItem) return -1;
    return 1;
  };

  useEffect(() => {
    const fetchCampaigns: () => void = async () => {
      const response = await fetchSanityData('editorPageCampaigns', { id: _id });
      setCampaigns(response.campaigns.sort(campaignSort));
    };

    fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disclaimerText = `${campaigns
    .map((campaign, index) => {
      if (campaign.disclaimer)
        return `${new Array(index + 1).fill('*').join('')}${campaign.disclaimer}`;
      return '';
    })
    .join('\n')}`;

  return (
    <>
      <HeroCTA
        overline={overline}
        title={title}
        description={description}
        image={image}
        primaryButton={
          loginOverride?.login
            ? {
                href: loginOverride.login.cta.url,
                linkText: loginOverride.linkText || loginOverride.login.cta.linkText,
              }
            : undefined
        }
        formOverride={formOverride}
      />

      {campaigns?.length > 0 && (
        <section className="bg-transparent">
          <div className="max-w-8xl mx-auto my-16 px-6 sm:my-20 sm:px-10 lg:my-28 lg:px-20 ">
            {campaigns.map((campaign, index) => {
              return campaign.campaignItem ? (
                <CampaignCard
                  key={campaign._id}
                  description={campaign.pitch}
                  title={campaign.title}
                  href={campaign.href || ''}
                  overline={campaign.overline}
                  image={campaign.image}
                />
              ) : (
                <CampaignBox key={campaign._id} campaign={campaign} itemNo={index + 1} />
              );
            })}
          </div>
        </section>
      )}

      {/* TOPP-OPPGAVER */}
      {/* // TODO: skal være tre i bredden når det kun er tre kort */}
      {/*  Skal ikke være space mellom toppbanner og inngangseksjon */}
      {/* Array.isArray(entryLists) &&
        entryLists?.map((list, i) => (
          <Section color={i % 2 === 1 ? 'info' : 'background'} key={list.listTitle}>
            <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{list.listTitle}</h2>
            <div className="grid gap-5 sm:grid-cols-6">
              {list.links.map((item, index) => {
                const { length } = list.links;

                if (!item.title || !item.href) return null;

                return (
                  <div
                    key={item.title}
                    className={cn(
                      index < 2 ? 'sm:col-span-3' : 'sm:col-span-2',
                      length % 2 === 0 ? 'sm:col-span-3' : '',
                    )}
                  >
                    <NavigationCard link={item} color={i % 2 === 1 ? 'info' : 'beige-light'} />
                  </div>
                );
              })}
            </div>
          </Section>
            )) */}
      {Array.isArray(entryLists) &&
        entryLists?.map((list, i) => (
          <Section key={list.listTitle}>
            <h2 className="mb-5 text-3xl font-semibold lg:mb-10 lg:text-4xl">{list.listTitle}</h2>
            {Array.isArray(list.links) &&
              list.links.map((item) => (
                <CampaignCard
                  key={item.title}
                  image={item.image}
                  title={item.title}
                  overline={item.overline}
                  href={item.href}
                  description={item.description}
                />
              ))}
          </Section>
        ))}

      {/* {Array.isArray(relatedEmployees) && (
          <div className="my-8 md:my-16">
            <h2 className="text-display-2 mb-6">Våre ansatte</h2>
            <div className="grid gap-4 md:grid-cols-2">
              {relatedEmployees.map((employee) => {
                return <EmployeeCard key={employee._id} employee={employee} />;
              })}
            </div>
          </div>
        )} */}

      <section className="bg-transparent">
        <div className="mx-auto my-10 max-w-3xl px-6 sm:px-10 lg:my-20">
          {disclaimerText || (disclaimerText !== '\n' && <Disclaimer text={disclaimerText} />)}
        </div>
      </section>
    </>
  );
}

export default EditorialPage;
