/* eslint-disable react/no-array-index-key */
import { cubicBezier, easeInOut, useAnimate, useInView } from 'framer-motion';
import { useEffect } from 'react';
import cn from '../utils/cn';

function StepsCar() {
  const [scope, animate] = useAnimate();
  let width = 0;
  if (scope.current) {
    width = scope.current.getBoundingClientRect().width;
  }

  const isInView = useInView(scope, { once: true, margin: '-75% 0px -25% 0px' });

  useEffect(() => {
    const poleWidth = 48;
    const distance = width / 6;
    const easing = cubicBezier(0.59, -0.01, 0.58, 1.08);
    const spinAnimation = {
      rotateY: [0, 180, 0],
    };
    const poleTransition = { duration: 1, ease: easeInOut };
    const carTransition = { duration: 2.5, ease: easing, at: '+1' };
    const carWiggle = { rotate: [0, -2, -2, -1, 0, 1, 1, 0] };

    function myAnimation() {
      animate(
        [
          [
            '#car',
            {
              opacity: [0, 1],
              ...carWiggle,
              x: [-distance, distance - poleWidth],
            },
            { duration: 2, ease: easing },
          ],
          ['#pole-1', spinAnimation, poleTransition],
          [
            '#car',
            {
              ...carWiggle,
              x: [distance - poleWidth, distance * 3 - poleWidth],
            },
            carTransition,
          ],
          ['#pole-2', spinAnimation, poleTransition],
          [
            '#car',
            {
              ...carWiggle,
              x: [distance * 3 - poleWidth, distance * 5 - poleWidth],
            },
            carTransition,
          ],
          ['#pole-3', spinAnimation, poleTransition],
          [
            '#car',
            {
              opacity: [1, 0],
              ...carWiggle,
              x: [distance * 5 - poleWidth, distance * 7 - poleWidth],
            },
            carTransition,
          ],
        ],
        { repeat: Infinity },
      );
    }

    if (isInView) {
      myAnimation();
    }
  }, [animate, isInView, width]);

  return (
    <div ref={scope} className="relative my-16 w-full">
      <div className="relative grid grid-cols-3 place-items-center border-b-2 border-b-black">
        {[...Array(3)].map((e, index) => {
          return <Pole key={index + 1} number={index + 1} />;
        })}
      </div>
      <Car />
    </div>
  );
}

function Car() {
  return (
    <svg
      width="56"
      height="30"
      viewBox="0 0 56 30"
      fill="none"
      id="car"
      className="absolute bottom-0 opacity-0"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46869 6.80021L11.532 2.05101C13.6162 0.821006 16.0078 0.171906 18.4337 0.171906H27.4537C30.0845 0.171906 32.647 0.923505 34.8678 2.35851L43.6487 8.06441C43.8878 8.23521 44.1612 8.3377 44.4003 8.406L51.1312 10.251C53.967 11.0369 55.7437 13.8044 55.2653 16.7085L54.787 19.5444C54.3087 22.4485 51.917 24.5669 49.047 24.7719C49.047 24.6181 49.0726 24.473 49.0982 24.3278C49.1239 24.1826 49.1495 24.0373 49.1495 23.8835C49.1495 19.6127 45.6645 16.1277 41.3937 16.1277C37.1228 16.1277 33.6378 19.6127 33.6378 23.8835C33.6378 24.0202 33.6549 24.1569 33.672 24.2935C33.6891 24.4302 33.7062 24.5669 33.7062 24.7035L23.1487 24.6352C23.1487 24.5156 23.1657 24.396 23.1828 24.2765C23.1999 24.1569 23.217 24.0373 23.217 23.9177C23.217 19.6469 19.732 16.1619 15.4612 16.1619C11.1903 16.1619 7.70529 19.6469 7.70529 23.9177C7.70529 24.0079 7.71479 24.0885 7.72379 24.1647C7.73189 24.2328 7.73949 24.2973 7.73949 24.3619C7.09029 24.1569 6.47529 23.9177 5.96279 23.6102L3.19529 21.7994C1.62369 20.706 0.666992 18.8952 0.666992 16.9819V11.7544C0.666992 9.7385 1.76029 7.85941 3.46869 6.83441V6.80021ZM9.48179 23.8835C9.48179 27.1635 12.1468 29.8627 15.461 29.8627C18.7752 29.8627 21.5 27.1977 21.5 23.8835C21.5 20.5694 18.7752 17.9044 15.461 17.9044C12.1468 17.9044 9.48179 20.5694 9.48179 23.8835ZM12.7618 23.8835C12.7618 22.3802 13.9577 21.1844 15.461 21.1844C16.9643 21.1844 18.1602 22.3802 18.1602 23.8835C18.1602 25.3869 16.9643 26.5827 15.461 26.5827C13.9577 26.5827 12.7618 25.3869 12.7618 23.8835ZM41.3596 29.8627C38.0454 29.8627 35.3804 27.1635 35.3804 23.8835C35.3804 20.5694 38.0454 17.9044 41.3596 17.9044C44.6738 17.9044 47.3388 20.5694 47.3388 23.8835C47.3388 27.1977 44.6738 29.8627 41.3596 29.8627ZM41.3596 21.1844C39.8563 21.1844 38.6604 22.3802 38.6604 23.8835C38.6604 25.3869 39.8563 26.5827 41.3596 26.5827C42.8629 26.5827 44.0588 25.3869 44.0588 23.8835C44.0588 22.3802 42.8629 21.1844 41.3596 21.1844Z"
        fill="#001946"
      />
    </svg>
  );
}

function Pole({ number }: { number: number }) {
  return (
    <div className={cn('relative ml-12 grid place-items-center')}>
      <Sign className="" number={number} />
      <div className="h-16 w-[3px] bg-slate-700" />
    </div>
  );
}

function Sign({ className = '', number }: { className?: string; number: number }) {
  return (
    <svg
      className={cn('text-[#E25F4D]', className)}
      width="40"
      height="39"
      viewBox="0 0 40 39"
      fill="none"
      id={`pole-${number}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4706 37.4265L1.59822 27.7255C1.21555 27.3494 1 26.8354 1 26.2989V12.5406C1 11.9987 1.21995 11.4799 1.60949 11.1031L11.4727 1.56247C11.8457 1.20169 12.3443 1 12.8632 1H26.9537C27.4781 1 27.9815 1.20594 28.3555 1.57346L38.0552 11.1048C38.4379 11.4809 38.6534 11.9949 38.6534 12.5314V26.2989C38.6534 26.8354 38.4379 27.3494 38.0552 27.7255L28.1828 37.4265C27.8088 37.7941 27.3054 38 26.781 38H12.8724C12.348 38 11.8446 37.7941 11.4706 37.4265Z"
        fill="currentColor"
        stroke="black"
      />
      <path
        d="M12.1825 36.7133L2.31669 27.0187C2.12535 26.8307 2.01758 26.5737 2.01758 26.3054V12.5387C2.01758 12.2677 2.12755 12.0083 2.32232 11.8199L12.1835 2.28124C12.37 2.10085 12.6193 2 12.8788 2H26.9333C27.1955 2 27.4472 2.10297 27.6342 2.28673L37.3366 11.8208C37.5279 12.0088 37.6357 12.2658 37.6357 12.534V26.3054C37.6357 26.5737 37.5279 26.8307 37.3366 27.0187L27.4708 36.7133C27.2838 36.897 27.0321 37 26.7699 37H12.8834C12.6212 37 12.3695 36.897 12.1825 36.7133Z"
        fill="currentColor"
        stroke="white"
      />
      {number === 1 && (
        <path
          d="M15.7751 12.248L18.9431 11.12H21.7031V27.92H18.3671V14.456L16.6151 15.056L15.7751 12.248Z"
          fill="black"
        />
      )}
      {number === 2 && (
        <path
          d="M14.2199 26.72C14.2199 21.92 21.6359 19.088 21.6359 15.992C21.6359 14.744 20.8199 13.928 19.4759 13.928C18.1079 13.928 17.4119 14.84 17.0999 15.896L14.1479 15.032C14.5319 13.352 15.9719 10.808 19.6199 10.808C22.8599 10.808 25.0919 12.776 25.0919 15.848C25.0919 20.36 18.8279 22.52 18.2279 24.968H25.2839V27.92H14.2199V26.72Z"
          fill="black"
        />
      )}
      {number === 3 && (
        <path
          d="M19.6199 28.232C15.9719 28.232 14.4839 25.88 14.0519 24.104L16.9559 23.192C17.2919 24.248 18.1079 25.136 19.5479 25.136C20.9399 25.136 21.8999 24.224 21.8999 22.832C21.8999 21.512 20.9639 20.552 19.1399 20.552H17.2919V17.936L20.7719 14.096H14.6999V11.12H24.6359V14.096L21.1079 17.792C23.2199 18.008 25.3319 19.712 25.3319 22.688C25.3319 25.952 22.9079 28.232 19.6199 28.232Z"
          fill="black"
        />
      )}
    </svg>
  );
}

export default StepsCar;
