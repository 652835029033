import { IoInformationCircleSharp } from 'react-icons/io5';

interface AlertCardProps {
  title: string;
  description: string;
}

function AlertCard({ title, description }: AlertCardProps) {
  return (
    <article className="bg-tertiary-container border-on-tertiary-container/5 relative flex gap-1 rounded-md border p-6 max-sm:flex-col sm:gap-4 md:p-10">
      <IoInformationCircleSharp className="text-tertiary-variant shrink-0 text-3xl sm:text-4xl" />
      <div className="flex flex-col gap-2">
        <h3 className="mt-[3px] text-xl font-semibold text-black/80 md:text-2xl">{title}</h3>
        <p className="text-md text-on-tertiary-container-container md:text-xl">{description}</p>
      </div>
    </article>
  );
}

export default AlertCard;
